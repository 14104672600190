@import "../../variables";

 .navigation {
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
   }
 }

 .logo {
  width: 15em;
 }

 .footer-flex-layout {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  @media (max-width: 767px) {
    flex-direction: column;
    row-gap: 20px;
   }
 }

 .nav-wrapper {
  margin: 0 auto;
  padding: 30px 100px;
  @media (max-width: 767px) {
    padding: 30px 20px;
   }
 }

 .footer-wrapper {
  margin: 0 auto;
  padding: 30px 100px;
  color: $secondary;
  @media (max-width: 767px) {
    padding: 30px 20px;
   }
 }

 .social-links-container {
  display: flex;
  column-gap: 20px;
  
 }

 .social-icon {
  background-color: $yellow;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .copyright-container {
  span {
    font-weight: bold;
    margin-right: 20px;
    margin-left: 10px;
  }

  a {
    color: $secondary;
  }
 }

 .btn:hover {
  color: white !important;
 }

 .nav-auth-container {
  display: flex;
  column-gap: 10px;
  align-items: flex-start;
  
  button, .username, .username-icon {
    color: white;
  }

  .username {
    // margin-top: 20px;
  }

  .btn-icon__icon-container {
    color: #928e8e;
  }

  .btn-icon .btn-icon__icon {
    height: 1.5em;
    width: 1.5em;
  }

 }

 .btn-login:hover {
  color: white;
  background-color: $orange;
 }

 .btn-register:hover {
  color: white;
  background-color: $orange;
 }

 a:hover {
  text-decoration: none !important;
 }


.section-content-wrapper {
  max-width: 60%;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 1290px) {
    max-width: 90%;
   }
}

.section-5-content-wrapper {
  max-width: 60%;
  margin: 0 auto;
  padding: 50px 0;
  @media (max-width: 1290px) {
    max-width: 90%;
   }
}

img.course-image {
  width: 100%;
}

.course-info-banner {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}


.tabs {
  display: flex;
  justify-content: center;
}

.tabitem {
  border: 2px solid #fff;
  cursor: pointer;
  width: 100px;
  height: 100px;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabitem--inactive {
  opacity: 0.3;
}

.tabitem__icon {
  font-size: 22px;
}

.tabitem__title {
  color: #121212;
  font-size: 16px;
  margin: 10px 0;
}

.content {
  text-align: center;
  margin-top: 15px;
  font-size: 22px;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
}

.about-course-tabs {
  text-align: left;
}

.section-1 {
  background: $orange;
  background: linear-gradient(90deg, rgb(234, 159, 8) 0%, rgb(209, 122, 5) 70%);
  
  h1, button {
    color: $white;
  }

  .heading-main {
    span {
      font-size: 5rem;
      @media (max-width: 767px) {
        font-size: 3.5rem;
       }
    }
  }

  h1 {
    font-size: 5rem;
    @media (max-width: 767px) {
      font-size: 3.5rem;
     }
  }
}

.scroll-down-container {
  margin-top: 110px;
  padding-bottom: 20px;
  cursor: pointer;

  h4 {
    color: white;
  }

  .scroll-down-icon {
    color: white;
    height: 50px !important;
  }
}

.btn-explore-courses {
  background-color: $orange !important;

  &:hover {
   background-color: $yellow !important;
  }
}

.Main-heading {
  
  text-align: left;
  @media (max-width: 767px) {
    font-size: 2rem;
   }
  }

.Heading {
  font-size: 2rem;
  font-weight: 500;
}

.pgn__card-footer{
  justify-content: flex-start !important;
}

.sign-in-banner {
  background-color: $darkBlue;
}

.sign-in-banner-flex-layout {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;

  h2 {
    color: white;
  }

  .sign-in-banner-content {
    color: white;
    flex: 1 1 0;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 10px;
   }
}

.sign-in-banner-content-title {
  font-size: 2.5rem !important;
}

.section-2 {

  h1, h2 {
    color: $primary;
  }
  h2 {
    font-weight: 400;
  }
  P{
   color: $secondary;
  }

  .pgn__card {
    box-shadow: none;
  }

  .pgn__icon {
   height: 3.5rem;
   width: auto;
   color: $secondary;
  }
}

.section-3 {
  background: linear-gradient(90deg, rgb(234, 159, 8) 0%, rgb(209, 122, 5) 70%);

  h1, p {
    color: white;
  }

  .pgn__card {
    background-color: rgba(255, 255, 255, 0.185);
    border-radius: 20px;
  }

  hr {
    border-top: 5px solid $primary;
    width: 100px;
  }
}

.section-3-flex {
  display: flex;
  gap: 50px;
  justify-content: center;
  .Heading {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
   }
}

.section-3-body-text {
  padding: 5px 0;
  font-size: 26px;

  span {
    font-weight: bold;
  }
}

.section-4 {

  .section-content-wrapper {
    padding-bottom: 20px !important;
  }

  h1, h3, h2 {
    color: $primary;
  }

  p {
    color: $secondary;
  }

  .pgn__icon {
    width: 3em;
    height: 5rem;
    color: $secondary;
  }

  .dropdown-arrow-icon {
    width: 6em;
    height: 2rem;
  }

  .pgn__card {
    background-color: rgba(255, 255, 255, 0.185);
    border-radius: 20px;
  }

  hr {
    border-top: 5px solid $primary;
    width: 100px;
  }

 
}

.section-4-two-card-layout {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-direction: column;
  
}

.section-4-subsection-flex-layout {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.section-4-card {
 p {
  margin-bottom: 0;
 }
}

.section-4-bullet-points {
  color: $secondary;

  span {
    font-weight: bold;
  }
}

.section-4-what-we-looking-for-section {
 background-color: $secondary;

 h1, h3, h2 {
  color: white;
}

p, ul {
  color: white;
}
}

.section-7 {
  background: rgb(108,124,39);
  background: linear-gradient(90deg, rgb(234, 159, 8) 0%, rgb(209, 122, 5) 70%);
  
  h2,h3, p, li {
    color: $white;
  }
  .pgn__card-wrapper-image-cap {
    max-height: 600px !important;
  }

}

.three-card-flex-layout {
  display: flex;
  gap: 50px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
   }

   .pgn__card-section {
    margin-top: 15px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}

.transparent-card-background {
  background-color: transparent !important;
  border-top: 6px solid rgba(164,46,22,1) !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.section-7-card-image-and-text {
  background-image: url("https://images.unsplash.com/photo-1504744373149-59d6d64c86f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80");
  background-size: cover;
  background-repeat: no-repeat;
}

.section-7-card-image-only {
  background-image: url("https://images.unsplash.com/photo-1466786784937-3e682c802b14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80");
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    height: 300px;
   }

}

.image-and-card-context-layout {
  display: flex;
  gap: 50px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 767px) {
    flex-direction: column;

    h3,li {
    text-align: center;
    }
   }

   .pgn__card-section {
    padding: 50px !important;
   }

   .card {
    border-radius: 1.375rem !important;
  }

  .pgn__card-wrapper-image-cap img {
    border-radius: 1.375rem !important;
  }
}


.section-6 {
  .pgn__card {
    box-shadow: none;
  }

  .pgn__icon {
   height: 3.5rem;
   width: auto;
  }

  .btn {
    color: white;
  }

  .section-6-flex-layout-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    @media (max-width: 767px) {
      flex-direction: column;
     }
  }

  .section-6-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: $primary;
  }

  a {
    color: $secondary;
  }

  p {
    color: $secondary;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.section-5 {
   background: linear-gradient(90deg, rgb(234, 159, 8) 0%, rgb(209, 122, 5) 70%);;

  h1, h2 {
    color: white;
  }

  .qr-container-layout {
    display: flex;
    flex-direction: column;
  }

  a {
    font-weight: bold;
    font-size: 1.5em;
    color: white;
  }
}

.free {
  width: 59px;
  height: 30px;
  border-radius: 15px !important;
  border: solid 2px #fff !important;
  background-color: #121212 !important;
}

.QR-image {
  width: 200px;
}

.business-matching-section {
  padding-top: 1px;
}